/* stylelint-disable */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font-family: 'Montserrat', sans-serif;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.mb-visible {
  display: none;
}
::placeholder {
  color: #DECFE2;
}
.calendar {
  position: relative;
  i {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    margin-right: 15px;
    color: #9D85A9;
  }
}
// GENERAL
.popupBooking {
  display: none;
}
.ads-service {
  background-color: #F8F5F8;
  padding-top: 20px;
}
.ads-banner {
  text-align: center;
  img {
    border-radius: 5px;
    max-width: 100%;
    height: auto;
    width: 800px;
  }
}
.open-popup-booking .popupBooking {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    content: "";
  }
  .contentPopup {
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    min-width: 400px;
    .content {
      padding: 35px 15px 35px !important;
      background: #FFF;
      position: relative;
      z-index: 10;
      overflow: hidden;
      text-align: center;
      h1 {
        font-weight: 700;
      }
      p {
        line-height: 20px;
        span {
          font-weight: 700;
          display: block;
        }
      }
      i {
        font-size: 60px;
        margin-bottom: 10px;
        color: #41937f;
      }
      span.close {
        display: block;
        margin-top: 20px;
        border: 1px solid #9669D1;
        display: block;
        width: 100px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        margin: 0 auto;
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        color: #000;
        font-weight: 700;
        margin: 0 auto;
        float: none;
        i {
          font-size: 14px;
          color: #000;
          margin: 0;
        }
      }
    }
  }
}
#topbar {
  background-color: #9669D1;
  color: #fff;
  font-size: 11px;
  overflow: hidden;
  padding: 11px 0;
  p {
    font-weight: 300;
    font-size: 11px;
  }
}

#wrapper {
  width: 100%;
  overflow: hidden;
}
header {
  padding: 0 15px;
  overflow: hidden;
  h1.logo {
    position: absolute;
    left: 50%;
    margin-left: -135px;
    margin-top: 20px;
    margin-bottom: 23px;
    a {
      display: block;
      width: 270px;
      height: 38px;
      text-indent: -99999px;
      background: url('../images/logo.svg') no-repeat 0 0;
      background-size: 100%;
    }
  }
  .nav {
    nav {
      width: 100%;
      overflow: hidden;
      ul.leftMenu,
      ul.rightMenu {
        float: left;
        margin-top: 30px;
        li {
          float: left;
          margin-left: 53px;
          a {
            display: block;
            font-size: 16px;
            font-weight: 500;
            color: #BEA0CC;
            text-decoration: none;
            padding-bottom: 20px;
            &:hover, &.active {
              color: #AF1F31;
              border-bottom: 2px solid #AF1F31;
            }
          }
        }
      }
      ul.rightMenu {
        float: right;
        margin-right: 45px;
      }
    }
  }
}
.carousel-indicators {
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0;
  li {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 1px solid #9669D1;
    margin: 0 7px;
    &.active {
      background-color: #9669D1;
    }
  }
}
.imageEffect {
  width: 1140px;
  margin: 0 auto;
  > div {
    float: right;
    margin-top: 30px;

    &.fadeInUp {
      margin-top: 500px;
    }
    &.fadeIn {
      opacity: 0;
    }
  }
}
.active {
  .imageEffect {
    > div {
      &.fadeInUp {
        margin-top: 25px;
      }
    }
  }
  
}
.imgResize75 {
  width: 75%;
}
.marginRight50 {
  margin-right: 50px;
}
.marginRight110 {
  margin-right: 110px;
}
.carousel-inner {
  background: url('../images/banner/bg1.jpg') no-repeat 0 0;
  background-size: cover;
  height: 500px;
  position: relative;
  .carousel-item {
    overflow: hidden;
    min-height: 500px;
    max-height: 500px;
    h1 {
      font-size: 48px;
      color: #41165A;
    }
    p {
      font-size: 18px;
      color: #9D85A9;
      margin: 15px 0;
      line-height: 24px;
      font-weight: 300;
    }
    .bookingNow {
      background: linear-gradient(194.8deg, #BD7AE3 0%, #8461C9 100%);
      border-radius: 25px;
      display: block;
      text-align: center;
      width: 159px;
      font-size: 16px;
      color: #fff;
      text-decoration: none;
      padding: 13px 0;
      margin-top: 20px;
    }
  }
  img {
    float: right;
  }
}
.carousel-item {
  .container {
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
 }
 
 .carousel-fade  .carousel-item.active,
 .carousel-fade  .carousel-item-next.carousel-item-left,
 .carousel-fade  .carousel-item-prev.carousel-item-right {
   opacity: 1;
 }
 
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-right {
  opacity: 0;
 }
 
 .carousel-fade  .carousel-item-next,
 .carousel-fade .carousel-item-prev,
 .carousel-fade .carousel-item.active,
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
 }
 
 #whyOurClients {
   margin: 70px 0 50px 0;
   .leftWhyOurClients {
      position: relative;
      img {
        position: absolute;
        transition: all 0.5s;
        margin-top: 0;
        &.imgMain {
          z-index: 3;
          left: 50%;
          top: 30px;
          transform: translateX(-50%);
        }
        &.bgPresent, &.bgPresent1 {
          z-index: 2;
        }
        &.bgPresent {
          top: 140px;
          margin-left: 23px;
        }
        &.bgPresent1 {
          right: 23px;
          top: 0;
        }
      }
   }
 }
.rightWhyOurClients {
  margin-top: 80px;
  h1 {
    font-size: 40px;
    color: #41165A;
    padding-right: 100px;
    padding-bottom: 30px;
  }
  p {
    color: #9D85A9;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
    font-weight: 300;
  }
}

#services {
  margin: 120px 0 0;
  h1 {
    font-size: 40px;
    text-align: center;
    color: #41165A;
  }
  .groupFeatures {
    position: relative;
    margin-top: 40px;
    overflow: hidden;
    &:before{
      background-color: #F8F5F8;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      top: 150px;
    }
    .container {
      width: 980px;
      height: 400px;
      padding: 0;
      overflow: hidden;
      .owl-carousel {
        margin-top: 30px;
        .itemDetail {
          display: none;
        }
        .owl-dots {
          text-align: center;
          margin-top: 0;
        }
        .owl-nav {
          position: relative;
          top: 80px;
          .owl-prev, .owl-next {
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #41165A;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            outline: none;
            margin-left: 100px;
            &:before {
              font-size: 32px;
              content: "\f104";
              color: #41165A;
            }
          }
          .owl-next {
            float: right;
            margin-right: 100px;

            &:before {
              font-size: 32px;
              content: "\f105";
            }
          }
        }
        button.owl-dot {
          margin: 0 5px;
          width: 14px;
          height: 14px;
          border: 1px solid #9669D1;
          border-radius: 50%;
          outline: none;
          &.active {
            background-color: #9669D1;
          }
        }
      }
      .owl-stage-outer {
        overflow: visible;
      }
      .owl-item {
        opacity: 0.5;
      }
      .currentItemResize {
        position: relative;
        z-index: 1;
        opacity: 1;
        .item {
          transition: all 1s;
          position: absolute;
          transform: scale(1.2);
          padding-bottom: 15px;
          background: url('../images/bg-item-slide.png') no-repeat bottom center;
          background-size: 100% auto;
          img {
            box-shadow: 0px -2px 3px 0 #ccc;
          }
        }
      }
    }

    .titleSlide {
      font-size: 30px;
      color: #AF1F31;
      position: absolute;
      bottom: 15px;
      text-align: center;
      width: 100%;
    }
  }

  .detailServices {
    padding-top: 20px;
    background-color: #F8F5F8;
    .container {
      width: 980px;
      padding: 0 100px;
      .itemDetail {
        padding-top: 30px;
        border-top: 1px solid #9D85A9;
        color: #41165A;
        font-size: 16px;
        line-height: 20px;
        h5 {
          text-transform: uppercase;
          padding-bottom: 4px;
          font-weight: bold;
        }
        p {
          position: relative;
          padding-right: 25px;
          white-space: nowrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 5px;
          span {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        p.smallText {
          color: #41165A;
          font-size: 13px;
          margin-top: 5px;
          text-align: right;
        }
        hr {
          border-top: 1px solid #9D85A9;
        }
      }
    }
  }
}

#gallery {
  background: url('../images/intersect.png') no-repeat 0 -660px;
  background-size: 100%;
  padding: 50px 0;
  h1 {
    font-size: 40px;
    color: #41165A;
    text-align: center;
  }
  .innerGallery {
    margin-top: 30px;
    > nav {
      text-align: center;
    }
  }
  #nav-tab {
    text-align: center;
    display: inline;
    border: 0;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 21px;
    position: relative;
    a {
      display: inline-block;
      border: 0;
      color: #9D85A9;
      font-size: 16px;
      padding: 10px 0;
      width: 220px;
      position: relative;
      z-index: 1;
      &.active {
        color: #FFF;
        background-color: transparent;
      }
    }
    &[data-tabActive="nav-Product"] {
      &:before {
        left: 0;
      }
    }
    &[data-tabActive="nav-Acrylic"] {
      &:before {
        left: 25%;
      }
    }
    &[data-tabActive="nav-Manicure"] {
      &:before {
        left: 50%;
      }
    }
    &[data-tabActive="nav-Waxing"] {
      &:before {
        left: 75%;
      }
    }
    &:before {
      background: linear-gradient(189.27deg, #BD7AE3 0%, #8461C9 100%);
      position: absolute;
      top: 0;
      height: 100%;
      width: 220px;
      content: "";
      border-radius: 25px;
      transition: left 0.5s; 
    }
  }
  .tab-content {
    width: 910px;
    margin: 45px auto;
  }
  .swiper-wrapper {
    height: auto;
  }
  .gallery-top {
    .swiper-slide {
      background-size: cover;
      height: 500px;
    }
  }
  .swiper-container-thumbs {
    margin-top: 5px;
    .swiper-slide{
      background-repeat: no-repeat;
      background-size: cover;
      height: 100px;
      opacity: 0.4;
    }
    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }
  
}

footer {
  background-color: #210830;
  a {
    color: #41165A;
    .fa {
      font-size: 24px;
      padding: 0 7px;
    }
    display: inline;
    span {
      display: inline !important;
      font-weight: 300 !important;
    }
  }
  .backgroundFooter {
    width: 100%;
    height: 300px;
    background: url('../images/footer-bg.jpg') no-repeat 0 0;
    background-size: cover;
    position: absolute;
  }
  .innerFooter {
    position: relative;
    z-index: 1;
    width: 100%;
    h1 {
      font-size: 40px;
      color: #FFF;
      text-align: center;
      padding: 50px 0 30px;
    }
    .contentBooking {
      background-color: #FFF;
      border-radius: 5px;
      .contactBooking {
        display: flex;
        padding: 15px 5px;
        padding-top: 20px;
        form {
          margin-top: 20px;
        }
        .content-group {
          margin-bottom: 25px;
          label {
            color: #41165A;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 5px;

            &.error {
              color: red;
              font-size: 12px;
              text-align: right
            }
          }
          input, textarea {
            width: 100%;
            border-radius: 22px;
            background: #F8F5F8;
            border: 0;
            font-size: 14px;
            padding: 10px 20px;
            outline: none;
          }
          textarea {
            height: 120px;
            line-height: 150%;
          }
        }
        button {
          background: linear-gradient(190.41deg, #BD7AE3 0%, #8461C9 100%);
          border-radius: 25px;
          color: #FFF;
          font-size: 14px;
          outline: none;
          border: none;
          width: 185px;
          height: 34px;
          cursor: pointer;
        }
      }
      .contactAddress {
        border-radius: 0 0 5px 5px;
        margin-top: 10px;
        overflow: hidden;
        background-color: #F8F5F8;
        padding: 20px;
        .contact {
          float: left;
          font-size: 14px;
          color: #41165A;
          font-weight: 300;
          margin-right: 70px;
          line-height: 18px;
          &.hotline {
            font-size: 24px;
            span {
              margin-bottom: 10px;
            }
          }
          &.social {
            span {
              margin-bottom: 5px;
            }
          }
          span {
            margin-top: 10px;
            display: block;
            color: #41165A;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
    .copyright {
      text-align: center;
      color: #FFF;
      font-size: 11px;
      margin: 30px 0;
      font-weight: 300;
    }
  }
}

// DESKTOP
@media (min-width:1600px) {
  #gallery {
    background-position: 0 -963px;
  }
}
@media (min-width:1200px) {
  
}
@media (min-width:768px) {
  
  #banner {
    
    .innerBanner {
      width: 1200px;
      margin: 0 auto;
      h1 {
        font-size: 40px;
        color: #194866;
        display: block;
        width: 474px;
        line-height: 43px;
        margin-top: 100px;
      }

      .groupBanner {
        position: absolute;
        top: 0;
        width: 100%;
        margin: 0 auto 355px;
        img {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          // setting 1
          &.object1 {
            top: 190px;
            margin-left: 96px;
          }
          // cicle
          &.object2 {
            top: 170px;
            margin-left: 350px;
          }
          // setting
          &.object3 {
            top: 124px;
            margin-left: 221px;
          }
          // girl 1
          &.object4 {
            top: 381px;
            margin-left: 470px;
          }
          // boy 
          &.object5 {
            margin-left: 243px;
            top: 236px;
          }
          // media
          &.object6 {
            top: 353px;
            margin-left: 380px;
          }
          // light
          &.object7 {
            top: 250px;
            margin-left: 470px;
          }
          //girl
          &.object8 {
            margin-left: -60px;
            top: 340px;
          }
          // phone
          &.object9 {
            left: 60%;
            top: 395px;
          }
          // talk
          &.object10 {
            top: 266px;
            margin-left: 0;
          }
        }
      }

      .createFree {
        margin: 100px 0 200px;
      }
    }
  }
  #buildStep {
    position: relative;
    padding-bottom: 10px;
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 60px;
    }
    ul {
      width: 900px;
      margin: 0 auto 20px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 312px;
        background-color: #f4faff;
        bottom: 0;
        left: 0;
        z-index: 0;
      }
      li {
        padding: 0 15px;
        color: #194866;
        text-align: center;
        width: 30%;
        float: left;
        position: relative;
        span {
          padding: 5px 20px 5px 10px;
          position: absolute;
          font-size: 14px;
          background-color: #D5EBFF;
          border-radius: 15px;
          text-align: center;
          top: 79px;
          left: 3px;
          strong {
            font-weight: 300;
          }
        }
        img {
          margin-left: 35px;
          position: relative;
          z-index: 1;
          margin: 0 auto;
        }
        p {
          text-align: center;
          font-weight: 300;
          margin-top: 14px;
          line-height: 18px;
          strong {
            display: block;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
  #features {
    overflow: hidden;
    text-align: center;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 0;
      background-color: #21628B;
    }
    h1 {
      font-size: 40px;
      font-weight: 500;
      margin-top: 80px;
      span {
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        width: 614px;
        margin: 0 auto;
      }
    }
    .featuresExpland {
      text-align: center;
      background-size: 100% auto;
      background-color: #21628B;
      position: relative;
      z-index: 1;

      > img {
        margin: 0 auto;
        position: relative;
        top: -25px;
      }
      ul {
        width: 100%;
        padding-bottom: 20px;
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        text-align: center;
        li {
          margin: 20px auto 0;
          padding-bottom: 30px;
          color: #fff;
          width: 33.3333%;
          float: left;
          overflow: hidden;
          > div {
            overflow: hidden;
            text-align: center;
            margin-right: 15px;
          }
          p {
            text-align: center;
            display: block;
            font-weight: 300;
            margin-top: 14px;
            line-height: 20px;
            padding: 0 60px;
            strong {
              display: block;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 7px;
            }
          }

          &.review {
            img {
              width: 138px;
            }
          }
          &.native {
            img {
              width: 103px;
            }
          }
          &.cloudfe {
            img {
              width: 113px;
            }
          }
        }
      }

    }
    .groupFeatures {
      position: relative;
      margin: 50px auto;
      width: 1200px;
      z-index: 2;
      img {
        position: absolute;
        z-index: 2;
      }
      .ins {
        left: 15px;
        top: 85px;
      }
      .yt {
        left: 195px;
        top: 190px;
      }
      .fb {
        left: 157px;
        top: -9px;
      }
      .tw {
        left: 875px;
        top: 82px;
      }
      .chat {
        left: 990px;
      }
      .cl {
        right: 0;
        top: 195px;
      }
    }
    .groupOptions {
      background-color: #21628B; 
      margin-top: -1px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      padding-bottom: 20px;
      width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      > div {
        width: 19%;
        display: inline-block;
        vertical-align: top;
        line-height: 15px;
        margin: 16px 0;
      }
      img {
        margin: 0 auto 15px;
        max-width: 47px;
        display: block;
      }    
      
      a {
        margin-top: 30px;
      }
    }
  }
  #download {
    overflow: hidden;
    text-align: center;
    .dashboard,.reviewApp {
      display: inline-block;
      vertical-align: top;
      padding: 0 40px;
      img {
        margin-bottom: 30px;
      }
    }
    .reviewApp {
      img {
        height: 111px;
      }
    }
  }
  #youtubeVideo {
    > div {
      width: 1200px;
      margin: 0 auto;
    }
  }
}

// TABLET
@media (min-width:768px) and (max-width:1023px) {
  header .nav nav ul.leftMenu, header .nav nav ul.rightMenu {
    float: left;
    margin-top: 80px;

    li {
      margin-left: 50px;
    }
  }
  .carousel-inner .carousel-item h1 {
    font-size: 36px;
  }
  #gallery {
    background-position: 0 0;
  }
  #topbar {
    p {
      text-align: center !important;
      float: none !important;
      line-height: 18px;
    }
  }
  #whyOurClients .leftWhyOurClients img {
    width: 70%;
    &.bgPresent, &.bgPresent1 {
      width: 57%;
    }
    &.bgPresent1 {
      margin-top: 10px;
    }
    &.bgPresent {
      margin-top: 73px;
    }
  }

  .rightWhyOurClients{
    margin-top: 25px;
    h1 {
      padding-right: 0px;
      font-size: 35px;
      padding-bottom: 12px;
    }
    p {
      font-size: 16px;
      margin-bottom: 13px;
      line-height: 20px;
    }
  }
  #services .groupFeatures {
    margin-top: 15px;
    &:before {
      top: 111px;
    }
  }
  
  #services .groupFeatures .container {
    height: 334px;
    width: 100%;
  }
  #gallery #nav-tab a {
    font-size: 13px;
    padding: 10px 24px;
  }
  #gallery .tab-content {
    width: 100%;
    margin-top: 30px;
  }
  .contact.logoFooter {
    display: block;
    width: 100%;
    text-align: center;
  }
  .contact.hotline {
    font-size: 20px;
  }
  footer .innerFooter .contentBooking .contactAddress .contact {
    margin-right: 14px;
    line-height: 18px;
  }
}
@media (min-width:768px) and (max-width:900px) {
  #whyOurClients .leftWhyOurClients img.bgPresent {
    margin-top: 45px;
    top: 0;
  }
  #whyOurClients .leftWhyOurClients img.bgPresent1 {
    margin-top: 10px;
  }
  #whyOurClients .leftWhyOurClients img.imgMain{
    top: 0;
  }
  #services .groupFeatures .container .currentItemResize .item {
    padding-bottom: 7px;
  }
  #gallery #nav-tab:before,
  #gallery #nav-tab a {
    width: 208px;
  }
  #gallery #nav-tab a {
    padding: 10px 8px;
  }
}
.popup, .popupHours, .navMobile {
  display: none;
}
.tab-content>.tab-pane {
  display: block;
  height: 0;
}
.tab-content>.active {
  height: auto;
}
.contentBooking iframe {
  width: 100%;
  height: 455px;
} 
// MOBILE
@media (min-width:600px) and (max-width:767px) {
  #services .groupFeatures .container {
    height: 250px;
  }
}

@media (max-width:767px) {
  #wrapper {
    background: url('../images/bg-block.jpg') no-repeat top center;
  }
  .contentBooking iframe {
    width: 100%;
    height: 300px;
  } 
  #whyOurClients .leftWhyOurClients {
    text-align: center;
  }

  #whyOurClients .leftWhyOurClients img.bgPresent {
    top: 105px !important;
    left: 0;
    margin-left: -105px !important;
  }
  #whyOurClients .leftWhyOurClients img.bgPresent1 {
    top: -202px !important;
    right: -105px;
    margin-left: 0 !important;
  }
  #whyOurClients {
    margin: 40px 0 0 0;
    padding: 20px 0 0;
    background: #fff !important;
    h1 {
      color: #41165A !important;
    }
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary,
  .btn-secondary:hover{
    background-color: transparent;
    border-color: transparent;
    color: #FFF;
  }
  .btn-secondary {
    padding-left: 0;
    padding-right: 0;
  } 
  .btn-secondary i {
    font-size: 20px;
  }
  .btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .navMobile {
    display: block;
  }
  #topbar {
    float: left;
    width: 100%;
    overflow: visible;
    padding: 2px 0;
    .dropdown-menu {
      right: 0;
      left: auto;
      padding: 0;
      width: 345px;
    }
    .dropdown-item {
      padding: 10px;
    }
  }
  #topbar p {
    display: none;
  }
  .popupHours {
    display: block;
    font-size: 11px;
    padding: 5px 0;
  }
  .open-popup {
    overflow: hidden;
  }
  .open-popup .popup, .open-popup-booking .popupBooking {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      content: "";
    }
    .contentPopup {
      padding: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 500px;
      min-width: 400px;
      .content {
        padding: 5px 15px 15px;
        background: #FFF;
        position: relative;
        z-index: 10;
        overflow: hidden;
        p {
          margin: 15px 0;
          line-height: 20px;
          span {
            font-weight: 700;
            display: block;
          }
        }
        span.close {
          border: 1px solid #9669D1;
          display: block;
          width: 100px;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
          margin: 0 auto;
          background-color: #fff;
          text-align: center;
          font-size: 14px;
          color: #000;
          font-weight: 700;
        }
      }
    }
  }
  .carousel-inner .carousel-item p {
    display: none;
  }
  header {
    height: 70px;
  }
  header .nav nav ul.leftMenu, header .nav nav ul.rightMenu {
    display: none;
  }
  header h1.logo {
    margin-left: -100px;
    a {
      width: 200px;
      height: 30px;
    }
  }
  .carousel-inner {
    height: 237px;
  }
  .imageEffect {
    width: 100%;
  }
  .carousel-fade .carousel-item {
    height: 237px;
    min-height: 0;
    max-height: auto;
    border-radius: 5px;
    overflow: hidden;
  }
  .marginRight110,
  .marginRight50 {
    margin-right: 0;
  }
  .carousel-inner .carousel-item h1 {
    font-size: 25px;
    margin-right: 120px;
  }
  .carousel-inner .carousel-item .bookingNow {
    width: 119px;
    font-size: 13px;
    padding: 8px 0;
  }
  .carousel-inner img {
    left: 46%;
    width: 45%;
    height: auto;
  }

  #whyOurClients .leftWhyOurClients {
    overflow: hidden;
    padding: 20px 0 5px 0;
    margin: 0 20px;
  }
  #whyOurClients .leftWhyOurClients img.imgMain {
    position: relative;
    width: 65%;
    transform: none;
    left: auto;
    top: 0;
    margin-bottom: 20px;
  }
  #whyOurClients .leftWhyOurClients img.bgPresent, #whyOurClients .leftWhyOurClients img.bgPresent1 {
    margin-top: 0;
    top: 20px;
  }
  #whyOurClients .leftWhyOurClients img.bgPresent {
    margin-top: 40px;
  }
  .rightWhyOurClients h1 {
    padding-right: 0;
  }
  #services {
    margin-top: 30px;
  }
  #services .groupFeatures {
    margin-top: 0;
    &::before {
      background: none;
    }
  }
  #services .detailServices {
    padding-bottom: 30px;
    background: none;
  }
  #services .groupFeatures .container,
  #services .detailServices .container,
  #gallery .tab-content {
    width: 100%;
  }
  #services .detailServices .container {
    padding: 0 15px;
  }
  #gallery {
    padding: 30px 0;
    padding-bottom: 0;
    background-color: #fff;
  }
  #gallery .swiper-container-thumbs {
    .swiper-slide {
      height: 40px;
    }
  }
  #gallery .gallery-top .swiper-slide img,
  #gallery .gallery-top .swiper-slide{
    height: 220px;
  }
  footer .innerFooter .contentBooking .contactBooking form {
    margin-bottom: 25px;
  }
  footer .innerFooter .contentBooking .contactBooking {
    display: inline;
  }
  #services .groupFeatures .container .owl-item {
    opacity: 1;
  }
  #services .groupFeatures .container .currentItemResize .item {
    transform: scale(1);
  }
  #services .groupFeatures .container .owl-carousel .owl-dots {
    margin-top: 25px;
  }
  #services .groupFeatures .container .owl-carousel .owl-nav {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -35px;
  }
  #services .groupFeatures .container .owl-carousel .owl-nav .owl-prev, #services .groupFeatures .container .owl-carousel .owl-nav .owl-next{
    border: 0;
    &::before{
      color: #fff;
      font-size: 70px;
    }
  }
  #services .groupFeatures .container .owl-carousel .owl-nav .owl-prev {
    margin-left: 0;
  }
  #services .groupFeatures .container .owl-carousel .owl-nav .owl-next {
    margin-right: 0;
    margin-left: 0;
    float: right;
  }
  #services .groupFeatures .titleSlide {
    font-size: 20px;
  }
  #gallery #nav-tab {
    display: inherit;
    padding: 4px 0;
    .owl-item {
      display: inline-block;
    }
    a {
      display: inherit;
      width: auto;
      color: #fff;
    }
  }
  #gallery #nav-tab:before {
    left: 50% !important;
    margin-left: -118px;
    width: 236px;
  }
  .owl-nav {
    position: absolute;
    width: 100%;
    top: 2px;
  }
  #gallery .tab-content {
    margin: 30px auto;
  }
  #gallery .owl-nav .owl-prev, #gallery .owl-nav .owl-next {
    display: block;
    width: 40px;
    height: 40px;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #41165A;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    &.owl-prev {
      float: left;
    }
    &.owl-next {
      float: right;
    }
    &.owl-prev, &.owl-next {
      &.disabled {
        opacity: 0.2;
      }
    }
    &.owl-prev:before, &.owl-next:before {
      font-size: 25px;
      content: "\f104";
      color: #41165A;
    }
    &.owl-next:before {
      font-size: 32px;
      content: "\f105";
    }
  }
  #gallery .innerGallery {
    overflow: hidden;
  }

  #services {
    h1 {
      font-size: 24px;
    }

    .owl-carousel {
      margin-top: 0 !important;
      width: auto;
      overflow: hidden;
      border-radius: 5px;
      .owl-stage-outer {
        border-radius: 5px;
      }
      .owl-nav {
        display: none;
      }
    }

    .groupFeatures {
      .container {
        height: auto;
      }
      .titleSlide {
        position: relative;
        bottom: 0;
        margin-top: 17px;
      }
    }

    .detailServices {
      .container .itemDetail {
        h5 {
          font-size: 12px;
        }
        p {
          font-size: 12px;
          &.smallText {
            font-size: 10px;
          }
        }
      }
    }
  }

  .m-no-margin {
    margin: 0 !important;
  }

  .m-no-padding {
    padding: 0 !important;
  }

  .rightWhyOurClients {
    margin: 0;
    padding: 0;
    h1 {
      font-size: 24px;
      padding: 0;
      margin: 0;
    }
    p {
      font-size: 14px;
      line-height: 120%;
    }
  }

  #gallery {
    h1 {
      font-size: 24px;
    }
    #nav-tab a {
      font-size: 15px;
    }
  }

  footer .innerFooter {
    h1 {
      font-size: 24px;
      padding: 30px 0 10px 0;
    }
    .contentBooking {
      margin: 10px 15px;
      .logoFooter {
        display: none;
      }
      .contactAddress .contact {
        text-align: center;
        float: none;
        margin: 0 0 20px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  #carouselBanner {
    margin: 0 15px;
    .carousel-inner {
      border-radius: 5px;
      overflow: hidden;
      position: relative;
    }
  }

  .m-block {
    background: url('../images/bg-block.jpg') no-repeat top center;
    background-color: #210830 !important;
    padding: 30px 0;
    h1 {
      color: #fff !important;
      margin-bottom: 30px;
      text-align: center;
    }
    .m-content {
      background-color: #fff;
      margin: 0 15px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
    }
  }
  .ads-service {
    background: none;
  }
}

.animated{animation-duration:1s;animation-fill-mode:both}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.active .fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.active .fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}